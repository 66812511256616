import { endpoints } from './api-endpoints/endpoints';

export const environment = {
	endpoints,
	allowedEngravingLength: 10,
	enableOutOfRangeButton: false,
	voucher: {
		minLength: 12,
		maxLength: 12,
		regex: {
			allowedRegex: /^[a-z0-9]+$/,
			errorMessage: 'Only alphanumeric characters allowed',
		},
		placeholder: '12 alphanumeric voucher code',
	},
	appMeta: {
		environment: 'DEV',
	},
	authConfig: {
		// ZEISS ID stage environment
		issuer: 'https://b2cstage.zeiss.com/483f449b-6a0d-4354-b7e7-37113e7d4ca1/v2.0/',
		clientId: '5519a679-9dc3-4bf9-91cb-4459c490ea06',
		discoveryDocumentUrl:
			'https://id-ip-stage.zeiss.com/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdNormalSignIn',
		selfServicePortalUrl: 'https://id-stage.zeiss.com/',
	},
	api: {
		headers: {
			authorization: '',
		},
		params: {},
	},
	rxPassUploadMaxFileSizeinBytesBinary: 8388608,
	rxPassUploadMaxFileSizeinMBText: '8MB',
	appInsights: {
		activated: false,
		instrumentationKey: '74798491-6f79-48cb-8208-b258b242870e',
	},
	azureAppConfig: {
		connectionString:
			'Endpoint=https://appcs-dop-shared-westeu-001.azconfig.io;Id=ZgZV-l9-s0:NevxEfwhBtztWwnRT1ro;Secret=29VMGWl68iqsqfu7AvzaAaWK+Wf+WcVsRGy0PPtGp6A=',
	},
	sampleImages: [
		'/assets/images/samples/sample1.png',
		'/assets/images/samples/sample2.png',
		'/assets/images/samples/sample3.png',
	],
};
