// prettier-ignore
 // this file is automatically generated by git.version.ts script
 export const Build = {
   appVersion: `0.0.0`,
   branch: `MAIN`,
   lastCommit: `79d916f`,
   lastCommitUrl: `https://dev.azure.com/zathena/Digital-Lens-Portal/_git/Frontend.DigitalLensPortal/commit/79d916f2acb0ab28cbe8fc7ec8a7e0ffd1ee14ce`,
   lastCommitHash: `79d916f2acb0ab28cbe8fc7ec8a7e0ffd1ee14ce`,
   lastCommitTime: `2025-02-13T13:02:25Z`,
   lastCommitMessage: `Merged PR 996: Disabled out of range button, changed wearer age text

# Changelog
- 'Out of range' button is no disabled (with config flag on environment)
- Changed text for wearer age

# Screenshots
![image.png](https://dev.azure.com/zathena/5de7aefb-8044-4dc4-82bd-625b12ec74ea/_apis/git/repositories/5d37b3c1-4bb0-440c-8427-cae7ccef62eb/pullRequests/996/attachments/image.png)
![image (2).png](https://dev.azure.com/zathena/5de7aefb-8044-4dc4-82bd-625b12ec74ea/_apis/git/repositories/5d37b3c1-4bb0-440c-8427-cae7ccef62eb/pullRequests/996/attachments/image%20%282%29.png)`,
   lastCommitAuthor: `Aaron Czichon`
 };
 